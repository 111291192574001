import { Routes } from '@angular/router';
import { profileGuard } from './guards/profile.guard';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.routes').then(m => m.routes),
  },
  {
    path: 'debug',
    loadComponent: () => import('./pages/general/debug/debug.page').then(m => m.DebugPage),
  },
  {
    path: 'onboarding',
    loadComponent: () => import('./pages/auth/onboarding/onboarding.page').then(m => m.OnboardingPage),
  },
  {
    path: 'welcome',
    loadComponent: () => import('./pages/auth/welcome/welcome.page').then(m => m.WelcomePage),
  },
  {
    path: 'profile',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/auth/profile/profile.page').then(m => m.ProfilePage),
  },
  {
    path: 'sign-in',
    loadComponent: () => import('./pages/auth/sign-in/sign-in.page').then(m => m.SignInPage),
  },
  {
    path: 'edit-profile',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/general/edit-profile/edit-profile.page').then(m => m.EditProfilePage),
  },
  {
    path: 'sign-up',
    loadComponent: () => import('./pages/auth/sign-up/sign-up.page').then(m => m.SignUpPage),
  },
  {
    path: 'inverter/:deviceId',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/inverter/pages/inverter/inverter.page').then(m => m.InverterPage),
  },
  {
    path: 'faq',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/general/faq/faq.page').then(m => m.FAQPage),
  },
  {
    path: 'about-us',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/general/about-us/about-us.page').then(m => m.AboutUsPage),
  },
  {
    path: 'settings',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/general/settings/settings.page').then(m => m.SettingsPage),
  },
  {
    path: 'app-settings',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/general/app-settings/app-settings.page').then(m => m.AppSettingsPage),
  },
  {
    path: 'logout',
    loadComponent: () => import('./pages/general/logout/logout.page').then(m => m.LogoutPage),
  },
  {
    path: 'sign-up-intro',
    loadComponent: () => import('./pages/auth/sign-up-intro/sign-up-intro.page').then(m => m.SignUpIntroPage),
  },
  {
    path: 'otp',
    loadComponent: () => import('./pages/auth/otp/otp.page').then(m => m.OtpPage),
  },
  {
    path: 'inverter-setup-settings',
    canActivate: [profileGuard],
    loadComponent: () =>
      import('./pages/inverter/pages/inverter-setup-settings/inverter-setup-settings.page').then(
        m => m.InverterSetupSettingsPage
      ),
  },
  {
    path: 'inverter-setup-wifi',
    canActivate: [profileGuard],
    loadComponent: () =>
      import('./pages/inverter/pages/inverter-setup-wifi/inverter-setup-wifi.page').then(m => m.InverterSetupWifiPage),
  },
  {
    path: 'notifications',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/general/notifications/notifications.page').then(m => m.NotificationsPage),
  },
  {
    path: 'contact',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/general/contact/contact.page').then(m => m.ContactPage),
  },
  {
    path: 'inverter-setup-done',
    canActivate: [profileGuard],
    loadComponent: () =>
      import('./pages/inverter/pages/inverter-setup-done/inverter-setup-done.page').then(m => m.InverterSetupDonePage),
  },
  {
    path: 'inverter-setup-search',
    canActivate: [profileGuard],
    loadComponent: () =>
      import('./pages/inverter/pages/inverter-setup-search/inverter-setup-search.page').then(
        m => m.InverterSetupSearchPage
      ),
  },
  {
    path: 'onboarding-form',
    loadComponent: () => import('./pages/auth/onboarding-form/onboarding-form.page').then(m => m.OnboardingFormPage),
  },
  {
    path: 'onboarding-done',
    loadComponent: () => import('./pages/auth/onboarding-done/onboarding-done.page').then(m => m.OnboardingDonePage),
  },
  {
    path: 'inverter/:deviceId/settings',
    canActivate: [profileGuard],
    loadComponent: () =>
      import('./pages/inverter/pages/inverter-settings/inverter-settings.page').then(m => m.InverterSettingsPage),
  },
  {
    path: 'warranty',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/general/warranty/warranty.page').then(m => m.WarrantyPage),
  },
  {
    path: 'inverter-setup-error',
    canActivate: [profileGuard],
    loadComponent: () =>
      import('./pages/inverter/pages/inverter-setup-error/inverter-setup-error.page').then(
        m => m.InverterSetupErrorPage
      ),
  },
  {
    path: 'feature-request',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/general/feature-request/feature-request.page').then(m => m.FeatureRequestPage),
  },
  {
    path: 'battery/:batteryId',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/battery/battery/battery.page').then(m => m.BatteryPage),
  },

  {
    path: 'group-location',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/group/group-location/group-location.page').then(m => m.GroupLocationPage),
  },
  {
    path: 'energie-plan',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/battery/energie-plan/energie-plan.page').then(m => m.EnergiePlanPage),
  },
  {
    path: 'battery/settings/brand',
    canActivate: [profileGuard],
    loadComponent: () =>
      import('./pages/group/select-inverter-brand/select-inverter-brand.page').then(m => m.SelectInverterBrandPage),
  },
  {
    path: 'battery/settings/solar-panel',
    canActivate: [profileGuard],
    loadComponent: () =>
      import('./pages/group/solar-panel-settings/solar-panel-settings.page').then(m => m.SolarPanelSettingsPage),
  },
  {
    path: 'groups/new/1',
    canActivate: [profileGuard],
    loadComponent: () =>
      import('./pages/group/group-setup-installation/group-setup-installation.page').then(
        m => m.GroupSetupInstallationPage
      ),
  },
  {
    path: 'groups/new/2',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/group/group-setup-name/group-setup-name.page').then(m => m.GroupSetupNamePage),
  },
  {
    path: 'groups/new/3',
    canActivate: [profileGuard],
    loadComponent: () =>
      import('./pages/group/group-setup-location/group-setup-location.page').then(m => m.GroupSetupLocationPage),
  },
  {
    path: 'potential',
    canActivate: [profileGuard],
    loadComponent: () => import('./pages/potential/potential.page').then(m => m.PotentialPage),
  },
  {
    path: 'potential/order-number',
    canActivate: [profileGuard],
    loadComponent: () =>
      import('./pages/potential/enter-order-number/enter-order-number.page').then(m => m.EnterOrderNumberPage),
  },
  {
    path: 'potential/order-number-group-update',
    canActivate: [profileGuard],
    loadComponent: () =>
      import('./pages/potential/enter-order-number-group-update/enter-order-number-group-update.page').then(
        m => m.EnterOrderNumberGroupUpdatePage
      ),
  },
  {
    path: 'battery-setup-instructions',
    loadComponent: () =>
      import('./pages/battery/battery-setup-instructions/battery-setup-instructions.page').then(
        m => m.BatterySetupInstructionsPage
      ),
  },
];
